@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Custom Styles */

/* Paragraph styles */
p {
	font-size: 1.125rem;
	color: #4b5563;
	line-height: 1.5;
}

/* Button styles */
/* button {
	padding: 0.75rem 1rem;
	background-color: #3b82f6;
	color: #fff;
	font-weight: 600;
	border-radius: 0.375rem;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition-property: background-color;
	transition-duration: 300ms;
	transition-timing-function: ease-in-out;
} */

/* button:hover {
	background-color: #2563eb;
} */

/* Textarea styles */
textarea {
	display: block;
	padding: 0.5rem 1rem;
	color: #161616;
	border: 1px solid #d1d5db;
	border-radius: 0.375rem;
	outline: none;
	transition: border-color 0.2s ease-in-out;
}

textarea:focus {
	border-color: #3b82f6;
	box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);
}

/* File input styles */
input[type="file"] {
	appearance: none;
	display: block;
	padding: 0.5rem 1rem;
	color: #161616;
	border: 1px solid #d1d5db;
	border-radius: 0.375rem;
	outline: none;
	transition: border-color 0.2s ease-in-out;
}

input[type="file"]:focus {
	border-color: #3b82f6;
	box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.2);
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: black;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
