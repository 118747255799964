body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.link {
	text-decoration: none;
}

.link:hover {
	text-decoration: underline;
	cursor: pointer;
	text-decoration-color: #eaeaea;
	text-underline-offset: 1.5px;
	text-decoration-thickness: 1.5px;
}

.checkbox {
	background-color: #e1e1e1;
	border-color: #161616;
}
